import { ReactElement, Suspense, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { RootState } from 'redux/store'

import CreditLimitIncreaseModal from 'App/CreditLimitIncreaseModal'
import { redirectToAuth } from 'authorization-module'
import Loader from 'authorization-module/ui/atoms/Loader'
import ProtectedRoute from 'authorization-module/ui/atoms/ProtectedRoute'
import {
  FIND_ACCOUNT,
  UNIVERSAL,
  clientUrls,
  liquidatedClients,
  nonSupportedClients
} from 'const/account/clients'
import config from 'const/config'
import featureFlags from 'const/featureFlags'
import routes from 'const/routes'
import { useAllAccountSummary, useCreditAccounts } from 'const/syfApiQueries'
import { GlobalAppStyles } from 'globalStyles'
import { getLanguageFromStorage } from 'helpers'
import authenticationRedirect from 'helpers/authenticationRedirect'
import getApigeeClientId from 'helpers/getApigeeClientId'
import getIvaUserMetadata from 'helpers/getIvaUserMetadata'
import 'helpers/iconLibrary'
import redirectToUrl from 'helpers/redirectToUrl'
import setLanguageFromQueryParams from 'helpers/setLanguageFromQueryParams'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { IVAChat } from 'iva-react-web-ui'
import AccountsDashboard from 'pages/AccountsDashboard'
import { FullPageRewardsError } from 'pages/AccountsDashboard/TechnicalError/subcomponents'
import AccountServices from 'pages/AccountServices'
import Activity from 'pages/Activity'
import Alerts from 'pages/Alerts'
import Callback from 'pages/Callback'
import CreditBalanceRefund from 'pages/CreditBalanceRefund'
import CreditView from 'pages/CreditView'
import DisputesCenter from 'pages/DisputesCenter'
import ErrorPage from 'pages/ErrorPage'
import InactivityModal from 'pages/InactivityModal'
import Logout from 'pages/Logout'
import PageLayout from 'pages/PageLayout'
import PageNotFound from 'pages/PageNotFound'
import MarketingAndPrivacy from 'pages/PreferenceSettings'
import Profile from 'pages/Profile'
import Rewards from 'pages/Rewards'
import updateFeatureFlags from 'services/updateFeatureFlags'
import { ThemeProvider } from 'styled-components'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'
import isMockData from 'syf-js-utilities/helpers/isMockData'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import theme from 'theme'
import Header from 'ui/atoms/Header'
import triggerQualtricsOnLoad from 'utils/triggerQualtricsOnLoad'

import CreditViewError from 'pages/CreditView/CreditViewError'
import PromotionsPage from 'pages/Promotions'
import VantageScore from 'pages/VantageScore'
import ActivityModal from './ActivityModal'
import AddAccountModal from './AddAccountModal'
import DetailsModal from './DetailsModal'
import FullPageRoute from './FullPageRoute'
import MakePaymentModal from './MakePaymentModal'
import ManageCardholderModal from './ManageCardholderModal'
import RewardsModal from './RewardsModal'
import StatementsModal from './StatementsModal'
import { AppWrapper, Footer, RouteWrapper } from './subcomponents'

const Dashboard = (): ReactElement => {
  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount: state.modals.selectedAccount
    }),
    shallowEqual
  )

  const isUsingMockData = isMockData(config.APP_ENV)

  const { data: { accounts = [] } = {} } = useCreditAccounts()
  const allAccountSummaryData = useAllAccountSummary(accounts)?.map(
    (result) => result.data
  )

  const ivaUserMetadata = getIvaUserMetadata(allAccountSummaryData)
  const location = useLocation()
  const [showRunningSections, setShowRunningSections] = useState(
    location.pathname !== routes.HOME
  )
  const [isScriptsLoaded, setIsScriptsLoaded] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(
    getLanguageFromStorage()
  )

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === 'en' ? 'es' : 'en'
    localStorage.setItem('language', newLanguage)
    document.documentElement.setAttribute('lang', newLanguage)
    setCurrentLanguage(newLanguage)
    analyticsConfig.defaults.Country_Language = `us|${newLanguage}`
    triggerAnalyticsEvent({ Country_Language: `us|${newLanguage}` })
  }

  const authorizedCallback = () => {
    setShowRunningSections(true)
  }

  const authenticateCallback = () => {
    if (isUsingMockData) return
    const client = getQueryParam('client') || localStorage.getItem('client')
    if (client) localStorage.setItem('client', client)
    const apigeeClientId = getApigeeClientId(client)
    const redirectUri = `${window.location.origin}${config.BASENAME}${routes.CALLBACK}`
    const isLiquidatedOrNonSupportedClient =
      liquidatedClients.includes(client) || nonSupportedClients.includes(client)

    if (
      Object.keys(clientUrls).includes(client) &&
      !isLiquidatedOrNonSupportedClient
    ) {
      authenticationRedirect()
    } else if (client === UNIVERSAL) {
      redirectToAuth({
        baseURL: config.AUTH_DOMAIN,
        clientId: apigeeClientId,
        redirectURI: redirectUri
      })
    } else {
      redirectToUrl(FIND_ACCOUNT)
    }
  }

  useEffect(() => {
    setLanguageFromQueryParams()
    updateFeatureFlags()
    document.documentElement.setAttribute('lang', currentLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setNewRelicCustomAttribute = () => {
    const correlationId = sessionStorage.getItem('correlationId')
    if (correlationId) {
      window?.newrelic?.setCustomAttribute('correlationId', correlationId)
    }
  }

  useEffect(() => {
    window.addEventListener('load', setNewRelicCustomAttribute)
    return () => {
      window.removeEventListener('load', setNewRelicCustomAttribute)
    }
  }, [])

  useEffect(() => {
    triggerQualtricsOnLoad(isScriptsLoaded, setIsScriptsLoaded)
  }, [isScriptsLoaded])

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        {showRunningSections && <GlobalAppStyles />}
        <AppWrapper data-test="dashboard">
          {showRunningSections && (
            <Header handleLanguageChange={handleLanguageChange} />
          )}
          <RouteWrapper>
            <Routes>
              <Route>
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      authenticateCallback={authenticateCallback}
                      authorizedCallback={authorizedCallback}
                      isMockData={isUsingMockData}
                    />
                  }
                >
                  <Route path="" element={<AccountsDashboard />} />
                </Route>
                <Route path={routes.CALLBACK} element={<Callback />} />
                <Route path={routes.ERROR} element={<ErrorPage />} />
                <Route element={<PageLayout />}>
                  <Route path={routes.ACTIVITY} element={<Activity />} />
                  <Route path={routes.DISPUTES} element={<DisputesCenter />} />
                  <Route
                    path={routes.PROMOTIONS}
                    element={
                      <FullPageRoute
                        featureFlag={featureFlags.isPromotionsMfeEnabled}
                      >
                        <PromotionsPage />
                      </FullPageRoute>
                    }
                  />
                  <Route
                    path={routes.ACCOUNT_SERVICES}
                    element={<AccountServices />}
                  />
                  <Route
                    path={routes.REWARDS}
                    element={
                      <FullPageRoute
                        featureFlag={featureFlags.isRewardsDeeplinkEnabled}
                      >
                        <Rewards />
                      </FullPageRoute>
                    }
                  />
                </Route>
                <Route path={routes.LOGOUT} element={<Logout />} />
                <Route
                  path={routes.REWARDS_ERROR}
                  element={<FullPageRewardsError />}
                />
                <Route
                  path={routes.CREDIT_BALANCE_REFUND}
                  element={<CreditBalanceRefund />}
                />
                <Route
                  path={routes.CREDIT_VIEW}
                  element={
                    <FullPageRoute
                      featureFlag={featureFlags.isCreditViewDashboardEnabled}
                    >
                      <CreditView />
                    </FullPageRoute>
                  }
                />
                <Route
                  path={routes.CREDIT_VIEW_ERROR}
                  element={<CreditViewError />}
                />
                <Route path={routes.PROFILE} element={<Profile />} />
                <Route path={routes.ALERTS} element={<Alerts />} />
                {featureFlags.isMarketingPrivacySettingEnabled && (
                  <Route
                    path={routes.MARKETING_PRIVACY}
                    element={<MarketingAndPrivacy />}
                  />
                )}
                {featureFlags.isVantageScoreMfeEnabled && (
                  <Route
                    path={routes.CREDIT_SCORE}
                    element={<VantageScore />}
                  />
                )}
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </RouteWrapper>
          {showRunningSections && (
            <>
              {location.pathname !== routes.LOGOUT && (
                <aside>
                  <ActivityModal />
                  {featureFlags.isCreditLimitIncreaseEnabled && (
                    <CreditLimitIncreaseModal />
                  )}
                  <DetailsModal />
                  <InactivityModal />
                  <MakePaymentModal />
                  {featureFlags.isRewardsDeeplinkEnabled && <RewardsModal />}
                  {featureFlags.isStatementsMfeEnabled && <StatementsModal />}
                  {featureFlags.isAddAccountEnabled && <AddAccountModal />}
                  {featureFlags.isManageCardHolderEnabled && (
                    <ManageCardholderModal />
                  )}
                </aside>
              )}
              <Footer />
            </>
          )}
          {featureFlags.isIvaEnabled && location.pathname !== routes.LOGOUT && (
            <IVAChat
              apiHostName={config.IVA_HOST}
              userMetadata={{
                currentEnterpriseId:
                  selectedAccount || ivaUserMetadata[0]?.enterpriseId || '',
                platformInformation: ivaUserMetadata || []
              }}
              isLoggedIn
              showIvaChatIcon
              platform="VISTA"
            />
          )}
        </AppWrapper>
      </ThemeProvider>
    </Suspense>
  )
}

export default Dashboard
