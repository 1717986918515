import { DisputesMFEConfig } from 'digital-disputes'
import getLanguageFromStorage from 'helpers/getLanguageFromStorage'
import { MmaMFEConfig } from 'mma-mfe/lib/src/services/mma-config'
import { MFEConfig } from 'syf-js-utilities/typings/mfeConfig'

import config from '../const/config'

type MFEConfigMap = {
  mfe: MFEConfig
  mma: MmaMFEConfig
  disputes: DisputesMFEConfig
}

const serviceFilesMmaUrl =
  config.APP_ENV === 'production'
    ? 'https://images.synchronycredit.com/serviceFiles/json/prod-mma-config.json'
    : `https://images.synchronycredit.com/serviceFiles/json/nonprod-mma-config.json`

const serviceFilesDisputesUrl =
  config.APP_ENV === 'production'
    ? 'https://images.synchronycredit.com/serviceFiles/json/prod-disputes-config.json'
    : 'https://images.synchronycredit.com/serviceFiles/json/nonprod-disputes-config.json'

const getMFEConfig = <T extends keyof MFEConfigMap>(
  mfeType: T
): MFEConfigMap[T] => {
  const baseMfeConfig = {
    hosts: {
      apigee: new URL(config.API_HOST),
      assets: new URL(config.ASSETS_HOST)
    },
    apiErrorCallback: (error) => error,
    lang: getLanguageFromStorage()
  }

  if (mfeType === 'mma') {
    return {
      ...baseMfeConfig,
      hosts: {
        ...baseMfeConfig.hosts,
        serviceFile: new URL(serviceFilesMmaUrl)
      }
    }
  }
  if (mfeType === 'disputes') {
    return {
      ...baseMfeConfig,
      hosts: {
        ...baseMfeConfig.hosts,
        serviceFile: new URL(serviceFilesDisputesUrl)
      }
    }
  }
  return baseMfeConfig as MFEConfigMap[T]
}

export default getMFEConfig
