import { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Stack } from 'syf-component-library/ui/spacing'
import { H4 } from 'syf-component-library/ui/typography'

import config from 'const/config'
import i18n from 'strings/i18n'
import routes from 'const/routes'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import { triggerAnalyticsEvent } from 'syf-js-utilities'
import {
  BackButton,
  ContentWrapper,
  CreditViewErrorContainer,
  CreditViewErrorImage,
  CreditViewErrorWrapper,
  ErrorContent
} from './subcomponents'

const CreditViewError = (): ReactElement => {
  const navigate = useNavigate()
  useNavigateOnRedirect()

  useEffect(() => {
    triggerAnalyticsEvent({
      PageFunction: 'creditview dashboard',
      PageKind: 'creditview',
      pageName: 'technical error'
    })
  }, [])

  return (
    <CreditViewErrorWrapper>
      <CreditViewErrorContainer>
        <CreditViewErrorImage
          src={`${config.ASSETS_HOST}/assets/vista/technical_error_cat.png`}
          alt={`${i18n({ creditView: 'errorTitle' })}`}
        />
        <ContentWrapper>
          <H4 as="h3" weight="bold">
            {i18n({ creditView: 'errorTitle' })}
          </H4>
          <Stack size="large" respondToBreakpoints={['small']} />
          <ErrorContent>{i18n({ creditView: 'errorMessage' })}</ErrorContent>
          <Stack size="base" />
          <ErrorContent>{i18n({ errors: 'pleaseTryAgain' })}</ErrorContent>
          <Stack size="xlarge" />
          <BackButton
            buttonType="primary"
            onClick={() => navigate(routes.HOME)}
            data-type="technical error"
            data-reason="account manager"
            data-object="button"
            data-analytics="clicktrackingevent"
          >
            {i18n({ creditView: 'accountManager' })}
          </BackButton>
        </ContentWrapper>
      </CreditViewErrorContainer>
    </CreditViewErrorWrapper>
  )
}

export default CreditViewError
