const { NODE_ENV } = process.env

export interface FeatureFlagsType {
  isAccountUpgradingMessageEnabled: boolean
  isAddAccountEnabled: boolean
  isCBREnabled: boolean
  isCobrowseEnabled: boolean
  isCreditLimitIncreaseEnabled: boolean
  isCreditViewDashboardEnabled: boolean
  isDYEnabled: boolean
  isDYScriptEnabled: boolean
  isIRLEnabled: boolean
  isIvaEnabled: boolean
  isLateFeeDateEnabled: boolean
  isManageCardHolderEnabled: boolean
  isManagePaymentMethodsEnabled: boolean
  isMarketingPrivacySettingEnabled: boolean
  isMarketPlaceEnabled: boolean
  isNotificationCenterEnabled: boolean
  isNotificationsEnabled: boolean
  isProfileManagementEnabled: boolean
  isPromotionsMfeEnabled: boolean
  isRewardsDeeplinkEnabled: boolean
  isSavingCheckingEnabled: boolean
  isShowCookieSetting: boolean
  isStatementsDeeplinkEnabled: boolean
  isStatementsMfeEnabled: boolean
  isTravelNotificationsMfeEnabled: boolean
  isVantageScoreMfeEnabled: boolean
}

const prodFeatureFlags: FeatureFlagsType = {
  isAccountUpgradingMessageEnabled: false,
  isAddAccountEnabled: false,
  isCBREnabled: true,
  isCreditLimitIncreaseEnabled: true,
  isCreditViewDashboardEnabled: false,
  isCobrowseEnabled: false,
  isDYEnabled: false,
  isDYScriptEnabled: false,
  isIRLEnabled: false,
  isIvaEnabled: true,
  isLateFeeDateEnabled: false,
  isManageCardHolderEnabled: true,
  isManagePaymentMethodsEnabled: true,
  isMarketingPrivacySettingEnabled: true,
  isMarketPlaceEnabled: false,
  isNotificationCenterEnabled: false,
  isNotificationsEnabled: true,
  isProfileManagementEnabled: true,
  isPromotionsMfeEnabled: false,
  isRewardsDeeplinkEnabled: true,
  isSavingCheckingEnabled: false,
  isShowCookieSetting: false,
  isStatementsDeeplinkEnabled: true,
  isStatementsMfeEnabled: true,
  isTravelNotificationsMfeEnabled: false,
  isVantageScoreMfeEnabled: true
}

const localFeatureFlags: FeatureFlagsType = {
  isAccountUpgradingMessageEnabled: true,
  isAddAccountEnabled: true,
  isCBREnabled: true,
  isCreditLimitIncreaseEnabled: true,
  isCreditViewDashboardEnabled: true,
  isCobrowseEnabled: true,
  isDYEnabled: true,
  isDYScriptEnabled: true,
  isIRLEnabled: true,
  isIvaEnabled: true,
  isLateFeeDateEnabled: false,
  isManageCardHolderEnabled: true,
  isManagePaymentMethodsEnabled: true,
  isMarketingPrivacySettingEnabled: true,
  isMarketPlaceEnabled: true,
  isNotificationCenterEnabled: true,
  isNotificationsEnabled: true,
  isProfileManagementEnabled: true,
  isPromotionsMfeEnabled: true,
  isRewardsDeeplinkEnabled: true,
  isSavingCheckingEnabled: true,
  isShowCookieSetting: true,
  isStatementsDeeplinkEnabled: true,
  isStatementsMfeEnabled: true,
  isTravelNotificationsMfeEnabled: true,
  isVantageScoreMfeEnabled: true
}

export default NODE_ENV === 'production' ? prodFeatureFlags : localFeatureFlags
