import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Inline, Inset, Stack } from 'syf-component-library/ui/spacing'
import { Small } from 'syf-component-library/ui/typography'
import debounce from 'syf-js-utilities/helpers/debounce'

import i18n from 'strings/i18n'
import {
  FAR_CHART_LINE,
  FAR_DOLLAR_SIGN,
  FAR_GLOBE,
  FAR_MAGNIFYING_GLASS,
  FAR_SQUARE_SLIDERS
} from 'const/icons'
import theme from 'theme'
import routes from 'const/routes'
import queryClient from 'const/queryClient'
import getCreditViewDashboardData from 'helpers/getCreditViewDashboardData'
import handleCreditViewPlatform from 'helpers/handleCreditViewPlatform'
import CrossBorderModal from 'App/CrossBorderModal'
import triggerAnalyticsEvent from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import {
  ButtonWrapper,
  ContentWrapper,
  CreditScoreBenefit,
  CreditViewSubtitle,
  FullWidthButton,
  GridItem,
  GridWrapper
} from './subcomponents'

interface CreditViewResponse {
  samlToken: string
  targetURI: string
}

interface CreditViewBenefits {
  id: number
  iconProp: IconProp
  benefitsKey:
    | 'scoreOverview'
    | 'managementTips'
    | 'scoreTrending'
    | 'nationalScore'
    | 'creditSimulator'
}

const creditScoreBenefits: CreditViewBenefits[] = [
  {
    id: 1,
    iconProp: FAR_MAGNIFYING_GLASS,
    benefitsKey: 'scoreOverview'
  },
  {
    id: 2,
    iconProp: FAR_DOLLAR_SIGN,
    benefitsKey: 'managementTips'
  },
  {
    id: 3,
    iconProp: FAR_CHART_LINE,
    benefitsKey: 'scoreTrending'
  },
  {
    id: 4,
    iconProp: FAR_GLOBE,
    benefitsKey: 'nationalScore'
  },
  {
    id: 5,
    iconProp: FAR_SQUARE_SLIDERS,
    benefitsKey: 'creditSimulator'
  }
]

const CreditViewContent = (): ReactElement => {
  const navigate = useNavigate()

  const { selectedAccount: accountId } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const creditViewDashboardCacheKey = ['credit-view-dashboard', accountId]
  const { data: creditViewDashboardData, isError: isCreditViewError } =
    useQuery<CreditViewResponse>(creditViewDashboardCacheKey, () =>
      getCreditViewDashboardData(accountId)
    )
  const [isCrossBorderVisible, setIsCrossBorderVisible] = useState(false)

  const creditViewButtonClick = () => {
    if (isCreditViewError) {
      navigate(routes.CREDIT_VIEW_ERROR)
      return
    }

    triggerAnalyticsEvent({
      PageFunction: 'creditview dashboard',
      PageKind: 'creditview',
      pageName: 'cross border popup'
    })
    setIsCrossBorderVisible(true)
  }

  const handleOpenTabCreditView = debounce(() => {
    const { samlToken, targetURI } = creditViewDashboardData

    handleCreditViewPlatform(samlToken, targetURI)
    queryClient.invalidateQueries(creditViewDashboardCacheKey)
    setIsCrossBorderVisible(false)
  }, 250)

  return (
    <>
      <ContentWrapper>
        <CreditViewSubtitle as="h2">
          {i18n({ creditView: 'creditViewSubtitle' })}
        </CreditViewSubtitle>
        <Stack size="large" />
        <GridWrapper>
          {creditScoreBenefits.map(({ iconProp, benefitsKey }) => (
            <GridItem key={benefitsKey}>
              <FontAwesomeIcon icon={iconProp} color={theme.cyanBlue} />
              <Inline size="medium" />
              <CreditScoreBenefit as="h3" weight="semiBold">
                {i18n({ creditView: benefitsKey })}
              </CreditScoreBenefit>
            </GridItem>
          ))}
        </GridWrapper>
        <Stack size="large" />
        <ButtonWrapper>
          <FullWidthButton
            buttonType="primary"
            onClick={creditViewButtonClick}
            type="button"
            data-type="creditview dashboard"
            data-reason="Launch creditview dashboard"
            data-object="button"
            data-analytics="clicktrackingevent"
          >
            {i18n({ creditView: 'launchCreditView' })}
          </FullWidthButton>
        </ButtonWrapper>
        <CrossBorderModal
          isOpen={isCrossBorderVisible}
          setIsOpen={setIsCrossBorderVisible}
          handleOpenTab={handleOpenTabCreditView}
          dataType="credit view"
        />
      </ContentWrapper>
      <Inset vertical="base" horizontal="medium">
        <Stack size="large" respondToBreakpoints={['small']} />
        <Small>{i18n({ creditView: 'disclaimer' })}</Small>
        <Stack size="medium" />
        <Small>{i18n({ creditView: 'footerDisclaimer' })}</Small>
      </Inset>
    </>
  )
}

export default CreditViewContent
